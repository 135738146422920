<template>
	<v-dialog v-model="clienteDialog" :max-width="400" scrollable>
		<v-card>
			<v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
			<v-card-title class="pb-2">
				<IconBase width="30" height="30" iconColor="#737373" class="mr-2"><IconPlugBank /></IconBase>
				PlugBank
				<v-spacer></v-spacer>
				<v-btn icon @click="dialogClose">
					<v-icon>close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<v-col>
					<v-row>
						<v-text-field :disabled="loading" v-model="plugbankToken" label="Token"/>
					</v-row>
					<v-row>
						<v-spacer />
						<v-btn v-if="cliente.plugbankToken" color="error" @click="desativar()" :disabled="loading" class="mr-4"
							>Desativar
						</v-btn>
						<v-btn color="primary" @click="setToken()" :disabled="loading || !plugbankToken">Salvar </v-btn>
					</v-row>
				</v-col>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import IconPlugBank from '../icons/IconPlugBank.vue'
import IconBase from '../shared/IconBase.vue'

export default {
	props: {
		cliente: Object,
		dialog: Boolean,
	},
	components: {
		IconBase,
		IconPlugBank,
	},
	data: function () {
		return {
			loading: false,
		}
	},
	computed: {
		...mapGetters(['userAdmin', 'userMaster']),
		...mapState(['user']),
		clienteDialog: {
			get() {
				return this.dialog
			},
			set() {
				this.dialogClose()
			},
		},
		plugbankToken:{
			get(){
				return this.cliente.plugbankToken
			},
			set(value){
				this.cliente.plugbankToken = value
			}
		}
	},
	methods: {
		dialogClose() {
			this.$emit('dialogClose')
		},
		async setToken() {
			try {
				this.loading = true
				const response = await this.axios.post(`/clientes/${this.cliente.id}/plugbank/token`, {
					token: this.plugbankToken,
				})
				this.$store.dispatch('showSuccess', response.data.msg)
				this.dialogClose()
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		async desativar() {
			try {
				this.loading = true
				const response = await this.axios.post(`/clientes/${this.cliente.id}/plugbank/desativar`)
				this.$store.dispatch('showSuccess', response.data.msg)
				this.dialogClose()
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false	
			}
		},
	},
}
</script>