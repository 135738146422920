<template>
	<div>
		<v-data-table :headers="headers" :items="computadores" disable-pagination hide-default-footer class="teste">
			<template v-slot:[`item.rtt_ultimo_acesso`]="{ item }">
				<span v-if="item.rtt_ultimo_acesso">
					{{ convertDate(item.rtt_ultimo_acesso) + " " + convertTime(item.rtt_ultimo_acesso) + " ; " +
						calcularDiferenca(item.rtt_ultimo_acesso) }}
				</span>
			</template>
			<template v-slot:[`item.createat`]="{ item }">
				<span v-if="item.createat">
					{{ convertDate(item.createat) + " " + convertTime(item.createat) }}
				</span>
			</template>
		</v-data-table>
	</div>
</template>
<style scoped>
.teste {
	white-space: nowrap
}
</style>

<script>

import { mapGetters } from 'vuex'
import { calcularDiferenca } from '../../utils/datetimeUtils.js'

export default {
	props: ['computadores'],
	data: () => ({
		headers: [
			{ text: 'Terminal', value: 'rtt_terminal' },
			{ text: 'Versão', value: 'rtt_sic_versao' },
			{ text: 'User(Terminal)', value: 'rtt_terminal_user' },
			{ text: 'User(SIC)', value: 'rtt_sic_user' },
			{ text: 'Últ. Acesso', value: 'rtt_ultimo_acesso' },
			{ text: 'Criado em:', value: 'createat' },
			{ text: 'Token', value: 'rtt_token' },
		]
	}),
	computed: {
		...mapGetters(['userAdmin']),
	},
	methods: {
		async removerComputador(id) {
			try {
				await this.axios.delete(`/computadores/${id}`)
				this.$emit('computadorChanged')
				this.$store.dispatch('showSuccess', 'Computador Removido com sucesso!')
			} catch (error) {
				this.$store.dispatch('showError', error)
			}
		}, calcularDiferenca(date) {
			return calcularDiferenca(date);
		},
		async ativarComputador(id) {
			try {
				await this.axios.put(`/computadores/${id}/ativo`, { ativo: true })
				this.$emit('computadorChanged')
				this.$store.dispatch('showSuccess', 'Computador Ativado com sucesso!')
			} catch (error) {
				this.$store.dispatch('showError', error)
			}
		},
		async desativarComputador(id) {
			try {
				await this.axios.put(`/computadores/${id}/ativo`, { ativo: false })
				this.$emit('computadorChanged')
				this.$store.dispatch('showSuccess', 'Computador Desativado com sucesso!')
			} catch (error) {
				this.$store.dispatch('showError', error)
			}
		}
	}
}
</script>