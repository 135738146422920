<template>
	<v-navigation-drawer v-model="inputValue" :clipped="$vuetify.breakpoint.lgAndUp" app>
		<v-list dense>
			<template v-for="item in links">
				<v-list-item :key="item.text" link :to="item.to" v-if="checkVisibility(item.admin)">
					<v-list-item-action>
						<IconBase width="25" height="25" iconColor="#737373" v-if="item.icon == 'pix'">
							<PixIcon />
						</IconBase>
						<v-icon v-else>{{ item.icon }}</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>{{ item.text }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</template>
		</v-list>
	</v-navigation-drawer>
</template>
<script>
import PixIcon from '../../components/icons/IconPix.vue'
import IconBase from '../../components/shared/IconBase.vue'
export default {
	components: { PixIcon, IconBase },
	data: () => ({
		links: [
			{
				to: '/clientes',
				icon: 'people',
				text: 'Clientes',
				admin: false,
			}, 
			{
				to: '/registro-terminal',
				icon: 'mdi-radiobox-marked',
				text: 'Registro de Terminal',
				admin: false,
			},
			{
				to: '/consultabloqueios',
				icon: 'mdi-cancel',
				text: 'Consulta Bloqueios',
				admin: 'bloqueios',
			},
			{
				to: '/boletos',
				icon: 'mdi-cash-multiple',
				text: 'Meus Boletos',
				admin: true,
			},
			{
				to: '/usuarios',
				icon: 'lock',
				text: 'Usuários',
				admin: true,
			},
			// {
			// 	to: '/admin',
			// 	icon: 'build',
			// 	text: 'Admin',
			// 	admin: true
			// },
			{
				to: '/computadores',
				icon: 'pageview',
				text: 'Computadores',
				//admin: true,
			},
			{
				to: '/relatorios',
				icon: 'mdi-file-excel',
				text: 'Relatórios',
				admin: 'relatorios',
			},
			{
				to: '/pxacertos',
				icon: 'mdi-tune',
				text: 'PxAcertos',
				admin: 'pxacertos',
			},
			{
				to: '/pix',
				icon: 'pix',
				text: 'Consulta PIX',
				admin: true,
			},
			{
				to: '/notasegura',
				icon: 'mdi-text-box',
				text: 'Nota Segura',
				admin: true,
			},
			{
				to: '/config',
				icon: 'settings',
				text: 'Configurações',
				admin: 'master',
			},
			{
				to: '/integracoes',
				icon: 'mdi-power-plug',
				text: 'Integrações',
				admin: 'integracoes',
			},
			{
				to: '/mensagens',
				icon: 'message',
				text: 'Mensagens',
				admin: 'mensagens',
			},
			{
				to: '/relatoriospadroes',
				icon: 'mdi-file-chart',
				text: 'Relatórios Padrões',
				admin: 'relatoriospadroes',
			}
		],
	}),
	computed: {
		inputValue: {
			get() {
				return this.$store.state.drawer
			},
			set(val) {
				this.$store.commit('setDrawer', val)
			},
		},
	},
	methods: {
		checkVisibility(admin) {
			if (admin == true && this.$store.getters.userAdmin) {
				return true
			} else if (admin == 'relatorios' && (this.$store.getters.userAdmin || this.$store.getters.userRelatorios)) {
				return true
			} else if (admin == 'pxacertos' && (this.$store.getters.userAdmin || this.$store.getters.userPxAcertos)) {
				return true
			} else if (admin == 'integracoes' && (this.$store.getters.userMaster || this.$store.getters.userIntegracoes)) {
				return true
			} else if (admin == 'relatoriospadroes' && (this.$store.getters.userMaster || this.$store.getters.userRelatoriosPadroes)) {
				return true
			} else if (admin == 'master' && this.$store.getters.userMaster) {
				return true
			} else if (admin == 'bloqueios' && (this.$store.getters.userMaster || this.$store.getters.userBloqueios)) {
				return true
			} else if (admin == 'mensagens' && (this.$store.getters.userMensagens)){
				return true
			}
			return !admin
		},
	},
}
</script>
