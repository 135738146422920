export function diasDiff(date) {
    if (!date)
        return 0;

    const agora = new Date();
    const ultimoAcesso = new Date(date);

    // calcular a diferença em milissegundos
    const diferencaMs = agora - ultimoAcesso;
    // calcular a diferença em minutos
    const diferencaDias = Math.abs(Math.floor(diferencaMs / 1000 / 60 / 60 / 24));
    return diferencaDias;
}

export function calcularDiferenca(ultimo_acesso) {
    const days = diasDiff(ultimo_acesso);
    if (days > 0)
        return `(${days} dias)`;

    const agora = new Date();
    const ultimoAcesso = new Date(ultimo_acesso);

    // calcular a diferença em milissegundos
    const diferencaMs = agora - ultimoAcesso;
    // calcular a diferença em minutos
    const diferencaMinutos = Math.abs(Math.floor(diferencaMs / 1000 / 60));

    if (diferencaMinutos >= 60) {
        return `(${Math.floor(diferencaMinutos / 60)} horas)`;
    } else {
        return `(${diferencaMinutos} minutos)`;
    }
}